<template>
  <a-spin :spinning='spinning'>
    <router-header :routerHeaderInfo="routerHeaderInfo" />
    <div class="live-setting__info is-flex mb24">
      <div ref="overTimeItem"
           class="live-setting__item">
        <a-statistic :value="timeUsage.remainTime?.toFixed(2)">
          <template #title>
            剩余时长（小时）
            <a-popover placement="left"
                       :getPopupContainer="() => $refs.overTimeItem">
              <template #content>
                “小时”是直播使用量的计算单位。
                <p class="gray">举例：10个学员观看2小时计为20小时。</p>
              </template>
              <svg-icon class="icon-yiwen"
                        type="iconyiwen" />
            </a-popover>
          </template>
        </a-statistic>
        <router-link :to="{name: 'system_setting_live_recharge'}">
          <a-button class="recharge-btn"
                    type="primary">去充值</a-button>
        </router-link>

      </div>
      <div class="live-setting__item mh32">
        <a-statistic title="本月使用量（小时）"
                     :value="timeUsage.currentMonthUsage?.toFixed(2)" />
      </div>
      <div class="live-setting__item">
        <a-statistic title="上月使用量（小时）"
                     :value="timeUsage.lastMonthUsage?.toFixed(2)" />
      </div>
    </div>

    <a-tabs v-model:active="tableActive">
      <a-tab-pane key="recharge"
                  tab="充值明细">
        <recharge-list />
      </a-tab-pane>

      <a-tab-pane key="bill"
                  tab="账单明细">
        <bill-list />
      </a-tab-pane>
    </a-tabs>

  </a-spin>
</template>

<script>
import { defineComponent, ref, reactive } from "vue";
import { Statistic, Popover, Tabs } from "ant-design-vue";
import _ from "lodash";

import RouterHeader from "@/components/RouterHeader";
import SvgIcon from "@/components/SvgIcon";
import RechargeList from "@/views/systemSetting/live/LiveRechargeList";
import BillList from "@/views/systemSetting/live/LiveBillList";

import liveApi from "@/service/api/live";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "LiveSystemSetting",
  props: [],
  emits: [],
  components: {
    RouterHeader,
    SvgIcon,
    RechargeList,
    BillList,

    AStatistic: Statistic,
    APopover: Popover,
    ATabs: Tabs,
    ATabPane: Tabs.TabPane,
  },
  setup() {
    const routerHeaderInfo = [
      {
        path: "/enterprise/systemSetting",
        name: "系统设置",
      },
      { name: "EduSoho直播配置" },
    ];

    const tableActive = ref("");

    const spinning = ref(false);

    // 判断是否已开通直播服务
    const router = new useRouter();
    const judgeIsOpen = async () => {
      const res = await liveApi.isOpenLiveAccount();

      if (!res) {
        router.push({
          name: "liveOpenClass_setting",
        });
        return;
      }

      getTimeUsage();
    };

    // 直播配置 -- 时长
    const timeUsage = reactive({});

    const getTimeUsage = async () => {
      const res = await liveApi.timeUsage();
      _.assign(timeUsage, res);
    };

    judgeIsOpen();

    return {
      spinning,
      routerHeaderInfo,

      tableActive,

      timeUsage,
    };
  },
});
</script>
<style lang='less' scoped>
.live-setting {
  &__item {
    flex: 1;

    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    padding: 0 64px;
    height: 188px;
    background-color: @bg-normal;

    .icon-yiwen {
      font-size: 16px;
      color: @icon;
    }

    :deep(.ant-popover-inner-content) {
      padding: 12px;
      width: 280px;
      color: @gray-darker;
    }

    .recharge-btn {
      position: absolute;
      right: 24px;
      bottom: 24px;
    }
  }
}
</style>