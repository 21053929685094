<template>
  <a-table :dataSource="rechargeList"
           :columns="rechargeColumns"
           :pagination="pagination"
           row-key="id"
           :loading="tableLoading"
           bordered
           @change="onPaginationChange">
    <template #createdTime="{ text }">
      {{ $f.datetime(text, 'YYYY-MM-DD HH:mm') }}
    </template>
    <template #cost="{ text }">
      {{ (text / 100).toFixed(2) }}
    </template>
  </a-table>
</template>

<script>
import { defineComponent, ref, reactive, onMounted } from "vue";
import _ from "lodash";
import { useRoute, useRouter } from "vue-router";

import liveApi from "@/service/api/live";

export default defineComponent({
  name: "LiveRechargeList",
  props: [],
  emits: [],
  components: {},
  setup() {
    // 充值明细
    const route = new useRoute();
    const router = new useRouter();

    const rechargeColumns = [
      {
        title: "充值日期",
        dataIndex: "createdTime",
        slots: { customRender: "createdTime" },
      },
      {
        title: "购买时长（人*小时）",
        dataIndex: "rechargeHour",
      },
      {
        title: "支付金额（元）",
        dataIndex: "cost",
        slots: { customRender: "cost" },
      },
      {
        title: "购买后剩余时长（小时）",
        dataIndex: "remainHour",
      },
    ];

    const tableLoading = ref(false);
    const rows = ref([]);
    const searchForm = reactive({
      radarId: route.query.id,
    });
    const pagination = reactive({
      current: 1,
      pageSize: 20,
      total: 0,
      pageSizeOptions: ["10", "20", "50"],
      showSizeChanger: true,
      showTotal: (total) => `共 ${total} 条`,
      showQuickJumper: true,
    });
    const pullQueryParams = (query) => {
      for (let key in searchForm) {
        searchForm[key] = query[key] ? query[key] : "";
      }

      if (query.page) {
        pagination.current = _.toInteger(query.page);
      }

      if (query.size) {
        pagination.pageSize = _.toInteger(query.size);
      }
    };
    const fetchPaginationData = async () => {
      tableLoading.value = true;
      const response = await liveApi.rechargeList({
        page: pagination.current - 1,
        size: pagination.pageSize,
      });
      rows.value = response.data;
      pagination.total = response.total;
      tableLoading.value = false;
    };
    const onPaginationChange = async (pagination, filters, sorter) => {
      let sortParams = {};
      if (sorter.field && sorter.order) {
        sortParams = {
          sort:
            sorter.field + "," + (sorter.order === "ascend" ? "asc" : "desc"),
        };
      } else {
        sortParams = { sort: "" };
      }
      const query = _.assign(
        {},
        route.query,
        { page: pagination.current, size: pagination.pageSize },
        sortParams
      );
      pullQueryParams(query);
      fetchPaginationData();
      router.push({ name: route.name, query: query });
    };

    onMounted(async () => {
      pullQueryParams(route.query);
      await fetchPaginationData();
    });

    return {
      rechargeColumns,
      rechargeList: rows,
      pagination,
      pullQueryParams,
      fetchPaginationData,
      onPaginationChange,
      tableLoading,
    };
  },

  beforeRouteUpdate(to, from, next) {
    next();
    this.pullQueryParams(to.query);
    this.fetchPaginationData();
  },
});
</script>
<style lang='less' scoped>
</style>